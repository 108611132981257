import { Injectable } from "@angular/core";
import { DocumentData } from "angularfire2/firestore";
import { Observable } from "rxjs";
import { FirebaseService } from "./firebase.service";
import { GService } from "./g.service";

@Injectable({
	providedIn: "root",
})
export class SearchService {
	// select item on cat -> side.page and prod -> side.page components
	selectedItem = "";
	// for search on prod -> side.page and prod -> main.page components
	searchTerm = "";
	lastSearch = "";
	productsList: DocumentData[] = [];
	displayedDoc: DocumentData[] = [];
	actualVisible;
	addProd: boolean = false;
	prodsIndex: number = 0;

	constructor(private g: GService, private fire: FirebaseService) { }

	cancelFilter() {
		this.searchTerm = '';
		this.actualVisible = '';
		this.addProd = false;
		this.displayedDoc = [];
		this.selectedItem = '';
		this.prodsIndex = 0;
	}

	resetFilter() {
		this.searchTerm = '';
		this.actualVisible = '';
		this.addProd = false;
		this.displayedDoc = [];
		this.selectedItem = '';
		this.prodsIndex = 0;
	}

	searchProducts() {
		if (this.lastSearch === '' && this.searchTerm === '') {
			//ricerca non attiva
			console.log("ricerca non attiva. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
		} else if (this.lastSearch !== '' && this.searchTerm !== '') {
			if (this.lastSearch !== this.searchTerm) {
				//ricerca già attivata, inserito nuovo carattere nella ricerca
				console.log("ricerca già attivata. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
				this.displayedDoc = [];
				this.prodsIndex = 0;
			} else {
				//ricerca avviata, scroll
				console.log("ricerca avviata, scroll. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
			}
		} else if (this.lastSearch !== '' && this.searchTerm === '') {
			//ricerca cancellata
			console.log("ricerca cancellata. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
			this.displayedDoc = [];
			this.prodsIndex = 0;
		} else if (this.lastSearch === '' && this.searchTerm !== '') {
			//ricerca iniziata
			console.log("ricerca iniziata. LAST: '" + this.lastSearch + "'; NEW: '" + this.searchTerm + "'")
			this.displayedDoc = [];
			this.prodsIndex = 0;
		}
		console.log('START AT: ', this.prodsIndex);
		this.fire.searchProds(this.searchTerm, this.prodsIndex).subscribe({
			next: (res: any) => {
				this.prodsIndex = res.index;
				res.products.forEach(async p => {
					let prodData: DocumentData;
					//SEDE
					this.fire.getProductDataByID(p.id).subscribe(
						data => {
							prodData = data;
							data.ingredienti.forEach(element => {
								if (element.composizione) {
									element.composizioneString = '';
									element.composizione.forEach((c, cIndex) => {
										element.composizioneString = element.composizioneString + c.titolo;
										if (cIndex < element.composizione.length - 1) {
											element.composizioneString = element.composizioneString + ' - ';
										}
									});
								}
							});
							if (prodData.immagine) {
								prodData.immaginiProdotto = { img: '', available: true };
								this.fire.readImage(prodData.immagine.id).then((resImg: string) => {
									prodData.immaginiProdotto.img = resImg;
								}, (err) => {
									console.log('error1', err)
								});
							} else {
								prodData.immaginiProdotto = { img: '', available: false };
							}
							this.lastSearch = this.searchTerm;
							this.displayedDoc.push(prodData);

						},
						err => {
							console.log(err)
						}
					);
				});
			},
			error: (err: Error) => {
				console.error("error error", err);
			}
		});
	}
}
