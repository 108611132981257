import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// FIRESTORE MODULES
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { firebaseConfig } from './firestore.config';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { HttpClientModule } from '@angular/common/http';
import { IonicGestureConfig } from './ionicgestureconfig';
import { FormsModule } from '@angular/forms';
import { ProdPageModule } from './pages/prod/prod.module';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { PipesModuleModule } from './pipes/pipes-module.module';
import { ConnectPageModule } from './pages/connect/connect.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AppRoutingModule,
    ProdPageModule,
    HttpClientModule,
    PipesModuleModule
  ],
  providers: [
    InAppBrowser,
    Network,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Device,
    File,
    FCM,
    ScreenOrientation,
    FileOpener,
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    BnNgIdleService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}

