import { Component } from "@angular/core";
import { Platform, ToastController, MenuController, PopoverController } from "@ionic/angular";
import { NavController } from "@ionic/angular";
import { GService } from "./services/g.service";
import { Device } from "@ionic-native/device/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { FCM } from "@ionic-native/fcm/ngx";
import { FirebaseService } from "./services/firebase.service";
import { Network } from "@ionic-native/network/ngx";
import { StorageService } from "./services/storage.service";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { SearchService } from "./services/search.service";
import { DocumentData } from "angularfire2/firestore";
import { NavigationEnd, NavigationError, NavigationStart, Router, Event } from "@angular/router";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import * as env from "src/environments/env.json";
import { TranslatePipe } from "./pipes/translate.pipe";
import { async } from "@angular/core/testing";

declare let navigator: any;
declare let document: any;

@Component({ selector: "app-root", templateUrl: "app.component.html", styleUrls: ["app.component.scss"] })
export class AppComponent {
	os: string;
	successToast: HTMLIonToastElement;
	errorToast: HTMLIonToastElement;
	noConnectionToast: HTMLIonToastElement;

	isConnectedToast: HTMLIonToastElement;
	menuDoc: DocumentData[] = [];
	getData = false;
	hiddenMenu = false;
	hiddenHome = true;
	prevLocation: string;
	actualLocation: string;
	appLoading = true;
	fullscreenBrowser = false;
	darkmode = false;

	initApp = false;
	env: any = (env as any).default;

	countries = this.env.countries;

	constructor(
		private platform: Platform,
		public g: GService,
		private navCtrl: NavController,
		private iab: InAppBrowser,
		private device: Device,
		private splashScreen: SplashScreen,
		private fcm: FCM,
		public toastCtrl: ToastController,
		public fireService: FirebaseService,
		private network: Network,
		public storage: StorageService,
		private screenOrient: ScreenOrientation,
		public search: SearchService,
		public menuCtrl: MenuController,
		private router: Router,
		public popoverCtrl: PopoverController,
		private translate: TranslatePipe
	) {
		this.initializeApp();
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (this.hiddenMenu) {
					this.hiddenMenu = false;
				}
				if (this.hiddenHome) {
					this.hiddenHome = false;
				}
				this.prevLocation = this.actualLocation;
				this.actualLocation = event.url;
				if (typeof this.actualLocation !== "undefined") {
					if (this.actualLocation === "/welcome") {
						this.g.hiddenHome = true;
						this.search.cancelFilter();
						return;
					} else if (this.actualLocation.includes("/prod")) {
						this.search.selectedItem = "";
						this.g.hiddenHome = true;
						return;
					}
				}
			}
			if (event instanceof NavigationError) {
				// Hide loading indicator
				console.log("NAV ERROR");
			}
		});
	}

	async initializeApp() {
		if (!this.g.initApp) {
			this.g.appPrefs.DBsource = "server";
			this.g.initApp = true;
			this.platform.ready().then(async () => {
				const that = this;
				setTimeout(() => {
					that.initConnection();
					that.g.appPrefs.width = this.platform.width() + 'px';
					that.g.appPrefs.height = this.platform.height() + 'px';
					//console.log("W:", this.g.appPrefs.width, "H:", this.g.appPrefs.height);

					// ####################### WEBMODE
					that.initSettings();
					that.g.appPrefs.webMode = true;
					console.log("Trying to get data from server...");

					this.fireService.enableNetwork();
					that.fireService.getWebData().subscribe({
						next: async () => {

							//TYPESENSE COLLECTION
							this.fireService.getTypesense();

							console.log("DOWNLOAD ALL DATA FROM SERVER SUCCESFULLY");
							that.g.appPrefs.catalogUnavailable = false;
							that.appLoading = false;
							that.getData = true;
							that.hiddenHome = false;
							that.navCtrl.navigateRoot(['/prod', {
								outlets: {
								  SideRouter: 'side',
								  MainRouter: 'products'
								}
							  }]);
						},
						error: async (err) => {
						},
					});

					// INIT PREFS
					that.storage.initPrefs();
					that.storage.initRequested();
				}, 500);
			});
		}
	}

	private async initConnection() {
		const status = this.network.type;
		if (status === "none") {
			this.g.appPrefs.bOffline = true;
			//this.noConnectionAlert();
		} else {
			this.g.appPrefs.bOffline = false;
			//this.isConnectedAlert();
		}
		const that = this;
		this.network.onChange().subscribe(() => {
			that.listenConnectionChanges();
		});
	}

	/* LISTEN CONNECTION CHANGES */
	private listenConnectionChanges() {
		if (this.network.type === "none") {
			//this.noConnectionAlert();
			this.g.appPrefs.bOffline = true;
		} else {
			//this.isConnectedAlert();
			this.g.appPrefs.bOffline = false;
		}
	}

	private async noConnectionAlert() {
		if (this.noConnectionToast) {
			this.noConnectionToast.dismiss();
		}
		if (this.isConnectedToast) {
			this.isConnectedToast.dismiss();
		}
		this.noConnectionToast = await this.toastCtrl.create({ message: "Connessione assente.", duration: 2000, color: "warning", position: "bottom" });
		this.noConnectionToast.present();
	}

	private async isConnectedAlert() {
		if (this.isConnectedToast) {
			this.isConnectedToast.dismiss();
		}
		if (this.noConnectionToast) {
			this.noConnectionToast.dismiss();
		}

		this.isConnectedToast = await this.toastCtrl.create({ message: "Dispositivo connesso.", duration: 2000, color: "success", position: "bottom" });
		this.isConnectedToast.present();
	}

	/* INIT SETTINGS FROM LANGUAGE */
	initSettings() {
		this.g.localPrefs = this.storage.getLocalPrefs();
		// #################### APPLICO DEFAULT
		if (typeof this.g.localPrefs.language === "undefined") {
			let navLang = navigator.language || navigator.userLanguage;
			let clientLang = navLang.split("-")[0];
			// CHECK IF CLIENT LANGUAGE IS AVAILABLE. OTHERWISE SET "IT" AS DEFAULT LANGUAGE
			if (this.g.catalogLanguages.includes(clientLang)) {
				this.g.localPrefs.language = clientLang;
			} else {
				this.g.localPrefs.language = this.env.language;
			}
		}

		this.storage.setLocalPrefs(this.g.localPrefs);
	}

	goTo(loc: string) {
		this.search.selectedItem = "";
		this.search.cancelFilter();
		if (loc === "welcome") {
			this.navCtrl.navigateBack("/" + loc);
		} else {
			this.navCtrl.navigateForward("/" + loc);
		}
		this.menuCtrl.close();
	}
}
