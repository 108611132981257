import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProdMainPage } from './prodmain.page';
import { TooltipDirective } from 'src/app/tooltip.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [ProdMainPage, TooltipDirective]
})
export class ProdMainPageModule {}
